import { userRef, singleUserRef } from "../config/firebase";
import {
  FETCH_ALL_USERS,
  FETCH_ALL_USERS_SUCCESS,

  EDIT_USER,
  EDIT_USER_SUCCESS,
  
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILED,
} from "./types";
import firebase from 'firebase/app';
import "firebase/firestore"
const db = firebase.firestore();

export const fetchUsers = () => dispatch => {
  dispatch({
    type: FETCH_ALL_USERS,
    payload: null
  });

  userRef.collection(`users`).get().then((querySnapshot) => {
    const arr = []
    querySnapshot.forEach((snapshot) => {
      let data = snapshot.data()
      arr.push(data)
    })
    
    dispatch({
      type: FETCH_ALL_USERS_SUCCESS,
      payload: arr
    });
  })
};

export const editUser = (userid, user) => dispatch => {

  dispatch({
    type: EDIT_USER,
    payload: user
  });


  const updateDBRef = db.collection('users').doc(userid);
  updateDBRef.update({
    block: user.block,
  }).then((docRef) => {
    window.location.reload(false);
    dispatch({
      type: EDIT_USER_SUCCESS,
      payload: null
    });
  }).catch((error) => {
    console.error("Error: ", error);
  });

}

export const deleteUser = (id) => dispatch => {
  console.log("DELETE USER 1 ==>>", id)
  dispatch({
    type: DELETE_USER,
    payload: id
  });
  const query = userRef.collection(`users`).doc(id);
  const querySnap1 = userRef.collection(`recentSearch`).doc(id);
        if (!querySnap1.empty) {
            querySnap1.delete()
        }
  query.delete().then((res) => {
      dispatch({
        type: DELETE_USER_SUCCESS,
        payload: id
      });
  }).catch(error => {
    console.log("error =>>>", error)
    dispatch({
      type: DELETE_USER_FAILED,
      payload: error
    });
  })
}