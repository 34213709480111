import React from 'react';
import {
  Typography,
  ListItemIcon,
  Divider,
  MenuList,
  MenuItem
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useDispatch } from "react-redux";

import logo from '../assets/logo1.png';
import ExitIcon from '@material-ui/icons/ExitToApp';
import PeopleIcon from '@material-ui/icons/People';
import languageJson from "../config/language";
import {
  signOut
} from "../actions/authactions";


function AppMenu() {
  const dispatch = useDispatch();
  const LogOut = () => {
    dispatch(signOut());
  };

  return (
    <div style={{ backgroundColor: '#252525' }}>
      <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#252525' }}>
        <img style={{ marginTop: '50px', marginBottom: '50px', width: '150px', height: '150px', borderRadius: 50 }} src={logo} alt="Logo" />
      </div>
      <Divider />
      <MenuList>

        <MenuItem component={Link} to="/">
          <ListItemIcon>
            <PeopleIcon style={{ backgroundColor: '#212121', color: 'white' }} />
          </ListItemIcon>
          <Typography style={{ color: '#ffffff' }} variant="inherit">{languageJson.all_user}</Typography>
        </MenuItem>

        <MenuItem component={Link} to="/Hinter">
          <ListItemIcon>
            <PeopleIcon style={{ backgroundColor: '#212121', color: 'white' }} />
          </ListItemIcon>
          <Typography style={{ color: '#ffffff' }} variant="inherit">{languageJson.hinters}</Typography>
        </MenuItem>

        <MenuItem component={Link} to="/Influancers">
          <ListItemIcon>
            <PeopleIcon style={{ backgroundColor: '#212121', color: 'white' }} />
          </ListItemIcon>
          <Typography style={{ color: '#ffffff' }} variant="inherit">{languageJson.influencers}</Typography>
        </MenuItem>

        <MenuItem component={Link} to="/Stylists">
          <ListItemIcon>
            <PeopleIcon style={{ backgroundColor: '#212121', color: 'white' }} />
          </ListItemIcon>
          <Typography style={{ color: '#ffffff' }} variant="inherit">{languageJson.stylists}</Typography>
        </MenuItem>

        <MenuItem component={Link} to="/Vendors">
          <ListItemIcon>
            <PeopleIcon style={{ backgroundColor: '#212121', color: 'white' }} />
          </ListItemIcon>
          <Typography style={{ color: '#ffffff' }} variant="inherit">{languageJson.vendors}</Typography>
        </MenuItem>

        <MenuItem onClick={LogOut}>
          <ListItemIcon>
            <ExitIcon style={{ backgroundColor: '#212121', color: 'white' }} />
          </ListItemIcon>
          <Typography style={{ color: '#ffffff' }} variant="inherit">{languageJson.logout}</Typography>
        </MenuItem>
      </MenuList>
    </div>
  );
}

export default AppMenu;