import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import languageJson from "../config/language";
import 'firebase/database';
import {
    deleteUser,
    editUser
} from "../actions/usersactions";
import moment from 'moment';

export default function Stylists() {
    const [data, setData] = useState([]);
    const [POMembers, setPOMembers] = useState({});
    const usersdata = useSelector(state => state.usersdata);

    const dispatch = useDispatch();

    useEffect(() => {

        const values = [{ value: 'no' }, { value: 'yes' }]
        let obj = {};
        values.map((driver) => obj[driver.value] = driver.value)
        setPOMembers(obj);

        if (usersdata.users) {
            const Users = usersdata.users;
            const CHECK = Users.filter((item) => item.accountType == "Stylist")

            setData(CHECK);

        }
    }, [usersdata.users]);



    const columns = [
        { title: 'Block', field: 'block', lookup: POMembers, filtering: false },
        { title: "Image", field: 'images', filtering: false, render: rowData => rowData.image ? <img alt='Profile' src={rowData.image} style={{ width: 70, height: 70, borderRadius: '50%' }} /> : null, editable: 'never' },
        { title: "Name", field: 'name', editable: 'never', render: rowData => rowData.name ? <p style={{ width: '200px',}}>{rowData.name}</p> : null},
        { title: "username", field: 'username', editable: 'never', render: rowData => rowData.username ? <p style={{ width: '200px',}}>{rowData.username}</p> : null},
        { title: "Email", field: 'email', editable: 'never' },
        { title: "Dob", field: 'dob', editable: 'never', filtering: false, render: rowData => rowData.dob ? <p>{(typeof rowData.dob === 'string') ? rowData.dob : moment(new Date(rowData.dob.seconds)).format("DD/MM/YYYY")}</p> : null, },

        { title: "Registration Date", field: 'createdAt', editable: 'never', filtering: false, render: rowData => rowData.createdAt ? <p>{ moment(new Date(rowData.createdAt.seconds * 1000)).format("DD/MM/YYYY")}</p> :null},
        
        { title: "Dob Year", field: 'dobYear', editable: 'never', filtering: false },
        { title: "Country", field: 'country', editable: 'never', filtering: false },
        { title: "State", field: 'state', editable: 'never', filtering: false },
        { title: "Zodiac Sign", field: 'zodiacSign', editable: 'never', filtering: false },
        { title: "Facebook", field: 'facebook', editable: 'never', filtering: false },
        { title: "Instagram", field: 'instagram', editable: 'never', filtering: false },
        { title: "Twitter", field: 'twitter', editable: 'never', filtering: false },
        { title: "Website", field: 'website', editable: 'never', filtering: false },

    ];

    return (
        usersdata.loading ? <CircularLoading /> :
            <MaterialTable
                title={languageJson.customers}
                columns={columns}
                data={data}
                options={{
                    exportButton: true,
                    filtering: true
                }}
                editable={{
                    onRowUpdate: (newData, oldData) =>
                        new Promise(resolve => {
                            setTimeout(() => {
                                resolve();
                                dispatch(editUser(oldData.id, newData));
                            }, 600);
                        }),
                    onRowDelete: oldData =>
                        new Promise(resolve => {
                            setTimeout(() => {
                                resolve();
                                dispatch(deleteUser(oldData.id));
                            }, 600);
                        }),
                }}
            />
    );
}
