import { 
    FETCH_ALL_USERS,
    FETCH_ALL_USERS_SUCCESS,
    FETCH_ALL_USERS_FAILED,
    DELETE_USER_SUCCESS,
  } from "../actions/types";
  
  export const INITIAL_STATE = {
    users:null,
    loading: false,
    error:{
      flag:false,
      msg: null
    }
  }
  
  export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_ALL_USERS:
        return {
          ...state,
          loading:true
        };
      case FETCH_ALL_USERS_SUCCESS:
        return {
          ...state,
          users:action.payload,
          loading:false
        };
      case FETCH_ALL_USERS_FAILED:
        return {
          ...state,
          users:null,
          loading:false,
          error:{
            flag:true,
            msg:action.payload
          }
        };

      case DELETE_USER_SUCCESS:
        const allusers = [...state.users]
        let filteredUser = allusers.filter((item) => item.id !== action.payload)
        return {
          ...state,
          users: filteredUser,
        };
        
      default:
        return state;
    }
  };