import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from "react-redux";
import { store } from "./reducers/store";
import ProtectedRoute from './components/ProtectedRoute';
import Login from './views/Login';

import Hinters from './views/Hinters';
import Influancers from './views/Influancers';
import Stylists from './views/Stylists';
import Vendors from './views/Vendors';
import AllUsers from './views/AllUsers';

import { fetchUser } from "./actions/authactions";
import AuthLoading from './components/AuthLoading';

function App() {
  store.dispatch(fetchUser());
  return (
    <Provider store={store}>
      <AuthLoading>
        <Router>
          <Switch>
            <ProtectedRoute exact component={AllUsers} path="/" />
            <ProtectedRoute exact component={Hinters} path="/Hinter" />
            <ProtectedRoute exact component={Influancers} path="/Influancers" />
            <ProtectedRoute exact component={Stylists} path="/Stylists" />
            <ProtectedRoute exact component={Vendors} path="/Vendors" />
            <Route component={Login} path="/login" />
          </Switch>
        </Router>
      </AuthLoading>
    </Provider>
  );
}

export default App;
